import JsonViewer from 'vue-json-viewer/ssr'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {

    nuxtApp.provide('jsonviewer', JsonViewer)
})

// declare module '#app' {
//     interface NuxtApp {
//         $dayjs: dayjs.Dayjs
//     }
// }