export default {
  "access-control-alt": () => import("/opt/build/repo/frontend/layouts/access-control-alt.vue").then(m => m.default || m),
  "access-control": () => import("/opt/build/repo/frontend/layouts/access-control.vue").then(m => m.default || m),
  "account-tiered-sidebar-settings": () => import("/opt/build/repo/frontend/layouts/account-tiered-sidebar-settings.vue").then(m => m.default || m),
  "account-tiered-sidebar": () => import("/opt/build/repo/frontend/layouts/account-tiered-sidebar.vue").then(m => m.default || m),
  account: () => import("/opt/build/repo/frontend/layouts/account.vue").then(m => m.default || m),
  "accreditation-basic": () => import("/opt/build/repo/frontend/layouts/accreditation-basic.vue").then(m => m.default || m),
  "admin-sidebar": () => import("/opt/build/repo/frontend/layouts/admin-sidebar.vue").then(m => m.default || m),
  altadmin: () => import("/opt/build/repo/frontend/layouts/altadmin.vue").then(m => m.default || m),
  "app-admin-sidebar": () => import("/opt/build/repo/frontend/layouts/app-admin-sidebar.vue").then(m => m.default || m),
  "app-communication-topbar": () => import("/opt/build/repo/frontend/layouts/app-communication-topbar.vue").then(m => m.default || m),
  authentication: () => import("/opt/build/repo/frontend/layouts/authentication.vue").then(m => m.default || m),
  basic: () => import("/opt/build/repo/frontend/layouts/basic.vue").then(m => m.default || m),
  blank: () => import("/opt/build/repo/frontend/layouts/blank.vue").then(m => m.default || m),
  "customer-account": () => import("/opt/build/repo/frontend/layouts/customer-account.vue").then(m => m.default || m),
  "default-old": () => import("/opt/build/repo/frontend/layouts/default-old.vue").then(m => m.default || m),
  default: () => import("/opt/build/repo/frontend/layouts/default.vue").then(m => m.default || m),
  error: () => import("/opt/build/repo/frontend/layouts/error.vue").then(m => m.default || m),
  fullheight: () => import("/opt/build/repo/frontend/layouts/fullheight.vue").then(m => m.default || m),
  "h-fullscreen": () => import("/opt/build/repo/frontend/layouts/h-fullscreen.vue").then(m => m.default || m),
  order: () => import("/opt/build/repo/frontend/layouts/order.vue").then(m => m.default || m),
  "scanner-basic": () => import("/opt/build/repo/frontend/layouts/scanner-basic.vue").then(m => m.default || m),
  sitedefault: () => import("/opt/build/repo/frontend/layouts/sitedefault.vue").then(m => m.default || m),
  "tenant-admin-old": () => import("/opt/build/repo/frontend/layouts/tenant-admin-old.vue").then(m => m.default || m),
  "tenant-admin-topbar": () => import("/opt/build/repo/frontend/layouts/tenant-admin-topbar.vue").then(m => m.default || m),
  "tenant-admin": () => import("/opt/build/repo/frontend/layouts/tenant-admin.vue").then(m => m.default || m),
  "tunes-admin": () => import("/opt/build/repo/frontend/layouts/tunes-admin.vue").then(m => m.default || m),
  user: () => import("/opt/build/repo/frontend/layouts/user.vue").then(m => m.default || m)
}