import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app/nuxt'

const components = {
  WetixSymbolIconColor: defineAsyncComponent(() => import("/opt/build/repo/frontend/components/global/WetixSymbolIconColor.vue" /* webpackChunkName: "components/wetix-symbol-icon-color" */).then(c => c.default || c)),
  WetixSymbolIconDark: defineAsyncComponent(() => import("/opt/build/repo/frontend/components/global/WetixSymbolIconDark.vue" /* webpackChunkName: "components/wetix-symbol-icon-dark" */).then(c => c.default || c)),
  WetixSymbolIconLight: defineAsyncComponent(() => import("/opt/build/repo/frontend/components/global/WetixSymbolIconLight.vue" /* webpackChunkName: "components/wetix-symbol-icon-light" */).then(c => c.default || c)),
  Icon: defineAsyncComponent(() => import("/opt/build/repo/frontend/node_modules/nuxt-icon/dist/runtime/Icon.vue" /* webpackChunkName: "components/icon" */).then(c => c.default || c)),
  IconCSS: defineAsyncComponent(() => import("/opt/build/repo/frontend/node_modules/nuxt-icon/dist/runtime/IconCSS.vue" /* webpackChunkName: "components/icon-css" */).then(c => c.default || c))
}

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const name in components) {
      nuxtApp.vueApp.component(name, components[name])
      nuxtApp.vueApp.component('Lazy' + name, components[name])
    }
  }
})
