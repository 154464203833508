import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_mik7N71GmK from "/opt/build/repo/frontend/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import templates_plugin_32fad657_IDY5pWRyUt from "/opt/build/repo/frontend/.nuxt/templates.plugin.32fad657.ejs";
import plugin_client_mLgZJrmjnn from "/opt/build/repo/frontend/node_modules/nuxt-vuefire/dist/runtime/auth/plugin.client.mjs";
import motion_zfwFQdqfVT from "/opt/build/repo/frontend/.nuxt/motion.js";
import plugin_client_i8AMfKeYnY from "/opt/build/repo/frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/frontend/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apexcharts_client_Dd5Ebxj7XB from "/opt/build/repo/frontend/plugins/apexcharts.client.ts";
import dayjs_pGiXRjcsJO from "/opt/build/repo/frontend/plugins/dayjs.ts";
import displayCurrency_guI5IqQ6cV from "/opt/build/repo/frontend/plugins/displayCurrency.ts";
import firebase_client_zXNDa0wxFH from "/opt/build/repo/frontend/plugins/firebase.client.ts";
import getImage_BBxTpbnDT4 from "/opt/build/repo/frontend/plugins/getImage.ts";
import gravatar_H9hJYGpdrS from "/opt/build/repo/frontend/plugins/gravatar.ts";
import jsonviewer_iZfMat4rlo from "/opt/build/repo/frontend/plugins/jsonviewer.ts";
import notifications_Wskzy5ZO71 from "/opt/build/repo/frontend/plugins/notifications.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/frontend/plugins/sentry.client.ts";
import unsplash_QvhLkKfy5n from "/opt/build/repo/frontend/plugins/unsplash.ts";
import vue_gtag_client_CYt6toK02h from "/opt/build/repo/frontend/plugins/vue-gtag.client.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_client_mik7N71GmK,
  templates_plugin_32fad657_IDY5pWRyUt,
  plugin_client_mLgZJrmjnn,
  motion_zfwFQdqfVT,
  plugin_client_i8AMfKeYnY,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_UciE0i6zes,
  apexcharts_client_Dd5Ebxj7XB,
  dayjs_pGiXRjcsJO,
  displayCurrency_guI5IqQ6cV,
  firebase_client_zXNDa0wxFH,
  getImage_BBxTpbnDT4,
  gravatar_H9hJYGpdrS,
  jsonviewer_iZfMat4rlo,
  notifications_Wskzy5ZO71,
  sentry_client_shVUlIjFLk,
  unsplash_QvhLkKfy5n,
  vue_gtag_client_CYt6toK02h
]