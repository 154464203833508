import VueGtag from 'vue-gtag'

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();

    if (config.public['ANALYTICS_DISABLE_ANALYTICS']) {
        console.log(`Analytics disabled via ANALYTICS_DISABLE_ANALYTICS variable.`);
    } else {
        // Inject vue-gtag
        if (config && config.ANALYTICS_GTAG_MEASUREMENT_ID) {
            console.log(`Initialising gtag with measurement ID ${config.public['ANALYTICS_GTAG_MEASUREMENT_ID']}`);
            nuxtApp.vueApp.use(VueGtag, {
                appName: `WeTix ${config.public['ENVIRONMENT']}`,
                pageTrackerScreenviewEnabled: true,
                config: {
                    id: config.ANALYTICS_GTAG_MEASUREMENT_ID
                }
            }, nuxtApp.$router)
        } else {
            console.error(`Analytics is enabled but no gtag measurement ID found in environment config.`);
        }
    }
})