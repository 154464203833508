// import { defineNuxtPlugin } from '#app'
// import { initializeApp } from "firebase/app";

// Firebase analytics imports
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
// Firebase Auth imports
import {
  getAuth,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
// Firestore imports
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  collectionGroup,
  query,
  where,
  onSnapshot,
  updateDoc,
  orderBy,
  limit,
  DocumentSnapshot,
  startAfter,
  startAt,
  getCountFromServer,
} from "firebase/firestore";

// Import @vueuse/firebase utils
// import { useAuth } from '@vueuse/firebase/useAuth'

// Import cart Pinia store
import { useAuthState } from "~/stores/auth";

// Type for orders query
interface OrdersQuery {
  path: string;
  limitTo?: number;
  eventId?: string;
  sortBy?: any;
  startDate?: string;
  endDate?: string;
  startAfterDoc?: DocumentSnapshot;
  startBeforeDoc?: DocumentSnapshot;
  startAtDoc?: DocumentSnapshot;
  orderId?: string;
}

// Type for collection query
interface CollectionQuery {
  path: string;
  sortBy?: any;
  eventId?: string;
  mailingListId?: string;
  limitTo?: number;
  startAtDoc?: DocumentSnapshot;
  startAfterDoc?: DocumentSnapshot;
}

export default defineNuxtPlugin((nuxtApp) => {
  // Get config from nuxtApp to use dynamic config
  const config = useRuntimeConfig();

  // // Dynamic firebase config
  // const firebaseConfig = {
  //   apiKey: config.FIREBASE_CONFIG_API_KEY,
  //   authDomain: config.FIREBASE_CONFIG_AUTH_DOMAIN,
  //   projectId: config.FIREBASE_CONFIG_PROJECT_ID,
  //   storageBucket: config.FIREBASE_CONFIG_STORAGE_BUCKET,
  //   messagingSenderId: config.FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  //   appId: config.FIREBASE_CONFIG_APP_ID,
  //   measurementId: config.FIREBASE_CONFIG_MEASUREMENT_ID,
  // };

  // Initialize firebase app
  // const wetixapp = initializeApp(firebaseConfig, 'wetix');
  const wetixapp = useFirebaseApp() // Get the same instance of firebase initialised by VueFire

  // Initialise Firebase auth
  const auth = getAuth(wetixapp);

  // Connect to auth emulator if in local dev
  if (config.WETIX_USE_FIREBASE_AUTH_EMULATOR) {
    connectAuthEmulator(auth, "http://localhost:9099");
  }

  // Initialise firebase firestore
  const db = getFirestore(wetixapp);

  // Initialise firebase analytics
  const analytics = getAnalytics(wetixapp);

  // Pinia auth state store
  const authState = useAuthState();

  // Auth state observer updates Pinia state
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      // console.log(`plugins/firebase.client - User with ${uid} is signed in`);
      authState.userLoggedIn(user);
    } else {
      // User is signed out
      authState.userLoggedOut(user);
    }
  });

  // Observer for when ID token changes, update wetix-cookie used for API calls.
  onIdTokenChanged(auth, async () => {
    if (!auth) return console.warn(`plugins/firebase.client - auth is null`);
    if (!auth.currentUser)
      return console.warn(`plugins/firebase.client - auth.currentUser is null`);
    try {
      const idToken = await auth.currentUser.getIdToken();
      if (!idToken)
        return console.warn(`plugins/firebase.client - idToken is null`);
      console.log(
        `firebase.client.ts:onIdTokenChanged - Updating wetix-token cookie.`
      );
      // Set active tenant cookie to first tenant in claims if not already set
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      // console.log(`firebase.client.ts:onIdTokenChanged - idTokenResult:`, idTokenResult);
      const activeTenantCookie = await useCookie("wetix-active-tenant");
      if (!activeTenantCookie.value) {
        activeTenantCookie.value =
          idTokenResult?.claims?.tenantadminTenants[0] || "no-default-tenant";
      }
      // console.log(`firebase.client.ts:onIdTokenChanged - activeTenantCookie:`, activeTenantCookie.value)
      // Update wetix-token cookie
      return (useCookie("wetix-token").value = `${idToken}`);
    } catch (error) {
      console.error(`An error occured during onIdTokenChanged observer.`);
    }
  });

  // Firebase action code settings - used for email/sms login.
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${window.location.origin} / login / code`, // TODO: Need to include ?userId or ?cartId here?
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //     bundleId: 'com.wetix.ios'
    // },
    // android: {
    //     packageName: 'com.wetix.android',
    //     installApp: true,
    //     minimumVersion: '12'
    // },
    // // dynamicLinkDomain: 'https://wetix.net/login/code'
  };

  // Create new user
  const newUser = async ({ email, password }) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        console.error("Error", error);

        const errorCode = error.code;
        const errorMessage = error.message;

        console.error(`${errorCode} - ${errorMessage}`);
        // ..
      });
  };

  // Function to send email sign-in link to user
  const firebaseEmailSignInLink = async ({ email }) => {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(
          `Error during firebaseEmailSignInLink - Code: ${errorCode} - Message: ${errorMessage}`
        );
        // ...
      });
  };

  // Function to check login code
  const doCheckLoginCode = () => {
    let loginResult = null;
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          return (loginResult = result);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.error(error);
        });
    }
    return loginResult;
  };

  // Function to do login with Google popup
  const doLoginWithGooglePopup = async () => {
    await signInWithPopup(auth, new GoogleAuthProvider());
  };

  return {
    provide: {
      // ---- AUTH HELPERS -----
      // Provide firebase auth
      firebaseAuth: auth,
      getCurrentAuthUser: () => {
        const a = getAuth(wetixapp);
        const user = a.currentUser;
        return a.currentUser;
      },
      // ---- FIRESTORE HELPERS -----
      firebase: wetixapp,
      firestore: db,
      dbCollection: async (path, pathSegments) => {
        const c = collection(db, path, pathSegments);
        return c;
      },
      // Gets all documents in a collection.
      dbGetCollection: async (options: CollectionQuery) => {
        const { path, limitTo, startAfterDoc, startAtDoc, sortBy, eventId } =
          options;
        const collectionRef = collection(db, path);
        //   Get the number of documents in the orders collection
        const docCountSnapshot = await getCountFromServer(collectionRef);
        const count = docCountSnapshot.data().count;
        let q = query(collectionRef);
        if (sortBy) q = query(q, orderBy(sortBy, "desc"));
        if (limitTo) q = query(q, limit(limitTo));
        if (eventId) q = query(q, where("eventId", "==", eventId));
        if (startAfterDoc) q = query(q, startAfter(startAfterDoc));
        if (startAtDoc) q = query(q, startAt(startAtDoc));
        const querySnapshot = await getDocs(q);
        // Get the first and last visible documents
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        return { docs, firstVisible, lastVisible, count };
      },
      // Gets a document from firestore from the given path and returns the data
      dbGetDoc: async (docPath) => {
        const docRef = doc(db, docPath);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists) {
          return docSnap.data();
        } else {
          console.error(`Document not found in firestore: ${docPath}`);
          return null;
        }
      },
      // Watches a document from firestore from the given path and adds data to app state, keyed under the db path name.
      dbWatchDoc: async (docPath) => {
        let useDb = useState(docPath, () => {}) as any;
        const unsubscribe = onSnapshot(doc(db, docPath), (doc) => {
          let docData = doc.data();
          useDb.value = { ...docData };
        });
        return { unsubscribe };
      },
      //Updates a document in firestore at the given path.
      dbUpdateDoc: async (docPath, updates) => {
        const docRef = doc(db, docPath);
        return await updateDoc(docRef, {
          ...updates,
        });
      },
      // Gets all events for a tenant.
      dbGetTenantEvents: async (tenantId: string, asc: boolean) => {
        if (!tenantId) return { docs: [] } // Return empty array if no tenantId provided (maybe pre-login)
        const path = `tenants/${tenantId}/events`;
        const collectionRef = collection(db, path);
        let q = query(collectionRef, orderBy("event.startDate", "desc"));
        if (asc) {
          q = query(collectionRef, orderBy("event.startDate", "asc"));
        }
        const querySnapshot = await getDocs(q);
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        return { docs };
      },
      // Gets a document from firestore from the given path and returns the data
      dbGetCustomerByEmail: async (customerEmail) => {
        const customersQuery = query(
          collectionGroup(db, "customers"),
          where("email", "==", `${customerEmail}`)
        );
        const querySnapshot = await getDocs(customersQuery);
        const foundCustomers = [];
        querySnapshot.forEach((doc) => {
          foundCustomers.push(doc.data());
        });
        return foundCustomers;
      },
      // Gets a document from firestore from the given path and returns the data
      dbGetCustomersOrdersByEmail: async (customerEmail) => {
        const customersQuery = query(
          collectionGroup(db, "orders"),
          where("customerSnapshot.email", "==", `${customerEmail}`)
        );
        const querySnapshot = await getDocs(customersQuery);
        const orders = [];
        querySnapshot.forEach((doc) => {
          orders.push(doc.data());
        });
        return orders;
      },
      // Gets tickets for an event.
      dbGetEventTickets: async (options: CollectionQuery) => {
        const { path, limitTo, startAfterDoc, startAtDoc, sortBy, eventId } =
          options;
        const collectionRef = collection(db, path);
        let q = query(collectionRef, where("eventId", "==", eventId));
        //   Get the number of documents in the orders collection
        const docCountSnapshot = await getCountFromServer(q);
        const count = docCountSnapshot.data().count;
        if (sortBy) q = query(q, orderBy(sortBy, "desc"));
        if (limitTo) q = query(q, limit(limitTo));
        if (startAfterDoc) q = query(q, startAfter(startAfterDoc));
        if (startAtDoc) q = query(q, startAt(startAtDoc));
        const querySnapshot = await getDocs(q);
        // Get the first and last visible documents
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        return { docs, firstVisible, lastVisible, count };
      },
      // Gets subscribers for a mailing list.
      dbGetMailingListSubscribers: async (options: CollectionQuery) => {
        const {
          path,
          limitTo,
          startAfterDoc,
          startAtDoc,
          sortBy,
          mailingListId,
        } = options;
        const collectionRef = collection(db, path);
        let q = query(
          collectionRef,
          where("mailingListId", "==", mailingListId)
        );
        //   Get the number of documents in the orders collection
        const docCountSnapshot = await getCountFromServer(q);
        const count = docCountSnapshot.data().count;
        if (sortBy) q = query(q, orderBy(sortBy, "desc"));
        if (limitTo) q = query(q, limit(limitTo));
        if (startAfterDoc) q = query(q, startAfter(startAfterDoc));
        if (startAtDoc) q = query(q, startAt(startAtDoc));
        const querySnapshot = await getDocs(q);
        // Get the first and last visible documents
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        return { docs, firstVisible, lastVisible, count };
      },
      // Gets all documents in orders collection.
      dbGetOrders: async (options: OrdersQuery) => {
        const {
          path,
          limitTo,
          eventId,
          sortBy,
          startDate,
          endDate,
          startAfterDoc,
          startAtDoc,
          orderId,
        } = options;

        let ordersRef = collection(db, path);
        //   Get the number of documents in the orders collection
        const orderCountSnapshot = await getCountFromServer(ordersRef);
        const count = orderCountSnapshot.data().count;
        let q = query(
          ordersRef,
          orderBy("meta.createdTimestamp", sortBy),
          limit(limitTo)
        );

        if (eventId) q = query(q, where("eventId", "==", eventId));
        if (startDate)
          q = query(q, where("meta.createdTimestamp", ">=", startDate));
        if (eventId && startDate)
          q = query(
            q,
            where("eventId", "==", eventId),
            where("meta.createdTimestamp", ">=", startDate)
          );
        if (endDate)
          q = query(q, where("meta.createdTimestamp", "<=", endDate));
        if (eventId && endDate)
          q = query(
            q,
            where("eventId", "==", eventId),
            where("meta.createdTimestamp", "<=", endDate)
          );
        if (startDate && endDate)
          q = query(
            q,
            where("meta.createdTimestamp", ">=", startDate),
            where("meta.createdTimestamp", "<=", endDate)
          );
        if (eventId && startDate && endDate)
          q = query(
            q,
            where("eventId", "==", eventId),
            where("meta.createdTimestamp", ">=", startDate),
            where("meta.createdTimestamp", "<=", endDate)
          );
        if (startAfterDoc) q = query(q, startAfter(startAfterDoc));
        if (orderId) q = query(ordersRef, where("id", "==", orderId));

        // TODO: startBeforeDoc
        if (startAtDoc) q = query(q, startAt(startAtDoc));

        const querySnapshot = await getDocs(q);

        // Get the first and last visible documents
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });

        return {
          orders: docs,
          querySnapshot,
          firstVisible,
          lastVisible,
          count,
        };
      },
      // ---- ANALYTICS HELPERS -----
      // Make firebase analytics available in nuxt context using $fbAnalytics()
      fbAnalytics: analytics,

      // Provide firebase analytics log event
      // @event: String (eg 'add_to_cart')
      // @parameters: Object (eg { currency: 'GBP', value: '7.77', items: [] })
      analyticsLogEvent: async ({ event, parameters }) => {
        // See gtag event reference for details: https://developers.google.com/tag-platform/gtagjs/reference/events
        logEvent(analytics, event, parameters);
        // Example:
        // logEvent(analytics, 'add_to_cart', {
        //     content_type: 'image',
        //     content_id: 'P12453',
        //     items: [{ name: 'Kittens' }]
        // });
      },
      analyticsSetUserProperties: async (properties) => {
        // See gtag event reference for details: https://developers.google.com/tag-platform/gtagjs/reference/events
        setUserProperties(analytics, { ...properties });
        // Example:
        // await $analyticsSetUserProperties({
        //     tenant_id: 'tunes',
        // });
      },
      // TODO: Analytics set user properties: setUserProperties(analytics, { favorite_food: 'apples' });
      // ---- AUTH HELPERS -----
      // Sign Out
      signOut: async () => {
        await auth.signOut();
        return navigateTo("/signedout");
      },
      // Create a user
      createUser: async ({ email, password }) => {
        await newUser({ email, password });
      },
      // Send email sign in link
      sendEmailSignInLink: async ({ email }) => {
        await firebaseEmailSignInLink({ email });
      },
      // Sign in with Google popup
      signInWithGooglePopup: async () => {
        const result = await doLoginWithGooglePopup();
        return true;
      },
      // Check a login code
      checkLoginCode: async () => {
        const codeLoginResult = await doCheckLoginCode();
        return codeLoginResult;
      },
    },
  };
});
