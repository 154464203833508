import { default as indexEuBnI0P78OMeta } from "/opt/build/repo/frontend/pages/account/index.vue?macro=true";
import { default as indexaN31tVGUB8Meta } from "/opt/build/repo/frontend/pages/account/settings/index.vue?macro=true";
import { default as guestlistYy46x5DCp6Meta } from "/opt/build/repo/frontend/pages/app/access-control/guestlist.vue?macro=true";
import { default as history5y1B3K8V9YMeta } from "/opt/build/repo/frontend/pages/app/access-control/history.vue?macro=true";
import { default as indextPq6tQSSe0Meta } from "/opt/build/repo/frontend/pages/app/access-control/index.vue?macro=true";
import { default as scanJOw61D1IRXMeta } from "/opt/build/repo/frontend/pages/app/access-control/scan.vue?macro=true";
import { default as settingsPuJVVNs12aMeta } from "/opt/build/repo/frontend/pages/app/access-control/settings.vue?macro=true";
import { default as accreditationL3mxXxHxLkMeta } from "/opt/build/repo/frontend/pages/app/access-control/view/accreditation.vue?macro=true";
import { default as customersUCRpSytTBMeta } from "/opt/build/repo/frontend/pages/app/access-control/view/customer.vue?macro=true";
import { default as orderHbUjvujhBqMeta } from "/opt/build/repo/frontend/pages/app/access-control/view/order.vue?macro=true";
import { default as thirdparty_45ticketTDAg6bQnc6Meta } from "/opt/build/repo/frontend/pages/app/access-control/view/thirdparty-ticket.vue?macro=true";
import { default as ticket4kRq4fCKDJMeta } from "/opt/build/repo/frontend/pages/app/access-control/view/ticket.vue?macro=true";
import { default as indexLvt9gpJLyIMeta } from "/opt/build/repo/frontend/pages/app/communication/audiences/index.vue?macro=true";
import { default as _91emailTemplateId_93RRhid0iCktMeta } from "/opt/build/repo/frontend/pages/app/communication/build/email/[emailTemplateId].vue?macro=true";
import { default as indexQ9UT3dwxzlMeta } from "/opt/build/repo/frontend/pages/app/communication/build/email/index.vue?macro=true";
import { default as _91campaignId_93EE6u9a8w2ZMeta } from "/opt/build/repo/frontend/pages/app/communication/campaigns/[campaignId].vue?macro=true";
import { default as createA8rINCSNYAMeta } from "/opt/build/repo/frontend/pages/app/communication/campaigns/create.vue?macro=true";
import { default as indexTgVS8dZCr9Meta } from "/opt/build/repo/frontend/pages/app/communication/campaigns/index.vue?macro=true";
import { default as indexJYre8oaxG5Meta } from "/opt/build/repo/frontend/pages/app/communication/index.vue?macro=true";
import { default as _91id_93UWDUf4nwQuMeta } from "/opt/build/repo/frontend/pages/app/communication/lists/[id].vue?macro=true";
import { default as index5BG1SQn5EJMeta } from "/opt/build/repo/frontend/pages/app/communication/lists/index.vue?macro=true";
import { default as indexfugg7kvhcwMeta } from "/opt/build/repo/frontend/pages/app/communication/media/index.vue?macro=true";
import { default as indexhvFWimHtrRMeta } from "/opt/build/repo/frontend/pages/app/communication/templates/index.vue?macro=true";
import { default as indexjgRdUeuDSUMeta } from "/opt/build/repo/frontend/pages/app/index.vue?macro=true";
import { default as indexzXfaulSN05Meta } from "/opt/build/repo/frontend/pages/app/promote/index.vue?macro=true";
import { default as indexLARuOfhcjmMeta } from "/opt/build/repo/frontend/pages/app/reports/index.vue?macro=true";
import { default as _91id_93dSRWUh3BSdMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/coupons/[id].vue?macro=true";
import { default as indexKS5twn2MZVMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/coupons/index.vue?macro=true";
import { default as create_45new_45eventpKWjyot2GUMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/create-new-event.vue?macro=true";
import { default as _91id_93eexqDrNQYfMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/customers/[id].vue?macro=true";
import { default as indexfrc83ekKTxMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/customers/index.vue?macro=true";
import { default as analyticsQvap96yhCJMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/analytics.vue?macro=true";
import { default as detailsbaAuzxi4KlMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/details.vue?macro=true";
import { default as duplicateONhrB2MZmkMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/duplicate.vue?macro=true";
import { default as guestlistJZGO3AhjywMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/guestlist.vue?macro=true";
import { default as import_45scanned_45ticketsm6FgcMQeMgMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/import-scanned-tickets.vue?macro=true";
import { default as import_45ticketspVZXhb78veMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/import-tickets.vue?macro=true";
import { default as imported_45ticketsBsULZIAMgbMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/imported-tickets.vue?macro=true";
import { default as integrations4fsiHKYzakMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/integrations.vue?macro=true";
import { default as issued_45ticketsgx8Ezp4NbHMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/issued-tickets.vue?macro=true";
import { default as notificationswFGPMxwtpxMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/notifications.vue?macro=true";
import { default as settingspMzPoUwoxTMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/settings.vue?macro=true";
import { default as shop_45settingsSfjYdI2AA1Meta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/shop-settings.vue?macro=true";
import { default as ticket_45typeshC4oe1daMJMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/ticket-types.vue?macro=true";
import { default as _91id_93fOLjFiJKazMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/[id].vue?macro=true";
import { default as indexT7T4OB7P6cMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/events/index.vue?macro=true";
import { default as helpVm5ohsXWAhMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/help.vue?macro=true";
import { default as index5K5HvETInoMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/index.vue?macro=true";
import { default as _91eventId_93heH3UVlizbMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/integrations/[integrationId]/[eventId].vue?macro=true";
import { default as indexs1etXyAchoMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/integrations/[integrationId]/index.vue?macro=true";
import { default as createluIil2hYPRMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/integrations/create.vue?macro=true";
import { default as indexd9yECM29YQMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/integrations/index.vue?macro=true";
import { default as logoutc5yPYgFrTvMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/logout.vue?macro=true";
import { default as messages16m79Pgg7jMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/messages.vue?macro=true";
import { default as _91id_93JhVWzouTDEMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/more/campaigns/[id].vue?macro=true";
import { default as communicationgJluZCdHwOMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/more/communication.vue?macro=true";
import { default as indexbmDukheGnVMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/more/emails/index.vue?macro=true";
import { default as indexl2ThBsZItlMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/more/image-library/index.vue?macro=true";
import { default as _91id_93yQxGgLp44oMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/more/mailing-lists/[id].vue?macro=true";
import { default as moreSDrAmObVDSMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/more.vue?macro=true";
import { default as indexuL5cRK1pRiMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/order-edit/[orderid]/index.vue?macro=true";
import { default as indexXXkXORQR2YMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/order-edit/index.vue?macro=true";
import { default as _91orderid_93fHRVfZkNxIMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/orders/[orderid].vue?macro=true";
import { default as indexqmh6k5eTszMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/orders/create/index.vue?macro=true";
import { default as indexiCSBDgmBn6Meta } from "/opt/build/repo/frontend/pages/app/tenant-admin/orders/index.vue?macro=true";
import { default as indexXLbLib51IOMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/orders/search/index.vue?macro=true";
import { default as _91orderId_93EQN7dHTZh5Meta } from "/opt/build/repo/frontend/pages/app/tenant-admin/orders/third-party-order/[orderId].vue?macro=true";
import { default as searchgEWPcj15vlMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/search.vue?macro=true";
import { default as settingsrL1AiMHklWMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/settings.vue?macro=true";
import { default as indexR0qurtrYngMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/tickets/index.vue?macro=true";
import { default as transactionspyXt2YxytIMeta } from "/opt/build/repo/frontend/pages/app/tenant-admin/transactions.vue?macro=true";
import { default as helpPYvfSan78yMeta } from "/opt/build/repo/frontend/pages/app/user/help.vue?macro=true";
import { default as indexpPgN9wEphdMeta } from "/opt/build/repo/frontend/pages/app/user/index.vue?macro=true";
import { default as ordersiNZcA9xyRsMeta } from "/opt/build/repo/frontend/pages/app/user/orders.vue?macro=true";
import { default as payment_45methodsZP5drF2E24Meta } from "/opt/build/repo/frontend/pages/app/user/payment-methods.vue?macro=true";
import { default as plansJSy2U5PWRzMeta } from "/opt/build/repo/frontend/pages/app/user/plans.vue?macro=true";
import { default as billingBrnctE89lOMeta } from "/opt/build/repo/frontend/pages/app/user/profile/billing.vue?macro=true";
import { default as generalj4B0xZ8NAWMeta } from "/opt/build/repo/frontend/pages/app/user/profile/general.vue?macro=true";
import { default as passwordhcADgrQb3nMeta } from "/opt/build/repo/frontend/pages/app/user/profile/password.vue?macro=true";
import { default as profilenps4DZEsATMeta } from "/opt/build/repo/frontend/pages/app/user/profile.vue?macro=true";
import { default as sharetDzigw0SHCMeta } from "/opt/build/repo/frontend/pages/app/user/share.vue?macro=true";
import { default as sign_45out0hNyVKbdWUMeta } from "/opt/build/repo/frontend/pages/app/user/sign-out.vue?macro=true";
import { default as alt_45account_45homeElYYXN3JyBMeta } from "/opt/build/repo/frontend/pages/dev/alt-account-home.vue?macro=true";
import { default as indexTXq0M4QMwvMeta } from "/opt/build/repo/frontend/pages/dev/index.vue?macro=true";
import { default as layout_452StXHrQTBEwMeta } from "/opt/build/repo/frontend/pages/dev/layout-2.vue?macro=true";
import { default as sidebarMqjMeGJX4pMeta } from "/opt/build/repo/frontend/pages/dev/sidebar.vue?macro=true";
import { default as indexEZrSTB9SUzMeta } from "/opt/build/repo/frontend/pages/download-tickets/index.vue?macro=true";
import { default as homegkrh4VISY0Meta } from "/opt/build/repo/frontend/pages/home.vue?macro=true";
import { default as indexdATqU9DngJMeta } from "/opt/build/repo/frontend/pages/index.vue?macro=true";
import { default as indexKU2ePCulYbMeta } from "/opt/build/repo/frontend/pages/landing/index.vue?macro=true";
import { default as codelqVRsXB9jEMeta } from "/opt/build/repo/frontend/pages/login/code.vue?macro=true";
import { default as email_45passwordy10Ck4sNI9Meta } from "/opt/build/repo/frontend/pages/login/email-password.vue?macro=true";
import { default as indexfWYCzO2tSqMeta } from "/opt/build/repo/frontend/pages/login/index.vue?macro=true";
import { default as welcomeDL4RkiDeRQMeta } from "/opt/build/repo/frontend/pages/login/welcome.vue?macro=true";
import { default as pricingnn376xy7e0Meta } from "/opt/build/repo/frontend/pages/pricing.vue?macro=true";
import { default as quickZuxhyLJOeaMeta } from "/opt/build/repo/frontend/pages/quick.vue?macro=true";
import { default as registerQpJpUBuRhoMeta } from "/opt/build/repo/frontend/pages/register.vue?macro=true";
import { default as indexvx6NLZESZCMeta } from "/opt/build/repo/frontend/pages/scan/code/index.vue?macro=true";
import { default as lookupPPapZD70wEMeta } from "/opt/build/repo/frontend/pages/scan/code/lookup.vue?macro=true";
import { default as index_45oldwhADTuJ6NSMeta } from "/opt/build/repo/frontend/pages/scan/index-old.vue?macro=true";
import { default as indexNp2KJn3craMeta } from "/opt/build/repo/frontend/pages/scan/index.vue?macro=true";
import { default as sign_45up_45landingWUu1L3Tsr7Meta } from "/opt/build/repo/frontend/pages/sign-up-landing.vue?macro=true";
import { default as sign_45up_45oldrLgX4jR7aoMeta } from "/opt/build/repo/frontend/pages/sign-up-old.vue?macro=true";
import { default as sign_45upUUsdSkSP41Meta } from "/opt/build/repo/frontend/pages/sign-up.vue?macro=true";
import { default as signedoutsJJLLejo1uMeta } from "/opt/build/repo/frontend/pages/signedout.vue?macro=true";
import { default as solutionslrpoCuuiPnMeta } from "/opt/build/repo/frontend/pages/solutions.vue?macro=true";
import { default as index4USKVWJu8sMeta } from "/opt/build/repo/frontend/pages/speech/index.vue?macro=true";
import { default as indexXDFAoRpbWzMeta } from "/opt/build/repo/frontend/pages/support/index.vue?macro=true";
import { default as indexOyC77DvOqwMeta } from "/opt/build/repo/frontend/pages/test/index.vue?macro=true";
import { default as landing1hBKg1FqIxDMeta } from "/opt/build/repo/frontend/pages/test/landing1.vue?macro=true";
import { default as landing2XtogRUPpxaMeta } from "/opt/build/repo/frontend/pages/test/landing2.vue?macro=true";
import { default as index1nxB9cfOXmMeta } from "/opt/build/repo/frontend/pages/tickets/index.vue?macro=true";
import { default as test841El2TaNqMeta } from "/opt/build/repo/frontend/pages/tickets/test.vue?macro=true";
export default [
  {
    name: indexEuBnI0P78OMeta?.name ?? "account",
    path: indexEuBnI0P78OMeta?.path ?? "/account",
    meta: indexEuBnI0P78OMeta || {},
    alias: indexEuBnI0P78OMeta?.alias || [],
    redirect: indexEuBnI0P78OMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexaN31tVGUB8Meta?.name ?? "account-settings",
    path: indexaN31tVGUB8Meta?.path ?? "/account/settings",
    meta: indexaN31tVGUB8Meta || {},
    alias: indexaN31tVGUB8Meta?.alias || [],
    redirect: indexaN31tVGUB8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: guestlistYy46x5DCp6Meta?.name ?? "app-access-control-guestlist",
    path: guestlistYy46x5DCp6Meta?.path ?? "/app/access-control/guestlist",
    meta: guestlistYy46x5DCp6Meta || {},
    alias: guestlistYy46x5DCp6Meta?.alias || [],
    redirect: guestlistYy46x5DCp6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/guestlist.vue").then(m => m.default || m)
  },
  {
    name: history5y1B3K8V9YMeta?.name ?? "app-access-control-history",
    path: history5y1B3K8V9YMeta?.path ?? "/app/access-control/history",
    meta: history5y1B3K8V9YMeta || {},
    alias: history5y1B3K8V9YMeta?.alias || [],
    redirect: history5y1B3K8V9YMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/history.vue").then(m => m.default || m)
  },
  {
    name: indextPq6tQSSe0Meta?.name ?? "app-access-control",
    path: indextPq6tQSSe0Meta?.path ?? "/app/access-control",
    meta: indextPq6tQSSe0Meta || {},
    alias: indextPq6tQSSe0Meta?.alias || [],
    redirect: indextPq6tQSSe0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/index.vue").then(m => m.default || m)
  },
  {
    name: scanJOw61D1IRXMeta?.name ?? "app-access-control-scan",
    path: scanJOw61D1IRXMeta?.path ?? "/app/access-control/scan",
    meta: scanJOw61D1IRXMeta || {},
    alias: scanJOw61D1IRXMeta?.alias || [],
    redirect: scanJOw61D1IRXMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/scan.vue").then(m => m.default || m)
  },
  {
    name: settingsPuJVVNs12aMeta?.name ?? "app-access-control-settings",
    path: settingsPuJVVNs12aMeta?.path ?? "/app/access-control/settings",
    meta: settingsPuJVVNs12aMeta || {},
    alias: settingsPuJVVNs12aMeta?.alias || [],
    redirect: settingsPuJVVNs12aMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/settings.vue").then(m => m.default || m)
  },
  {
    name: accreditationL3mxXxHxLkMeta?.name ?? "app-access-control-view-accreditation",
    path: accreditationL3mxXxHxLkMeta?.path ?? "/app/access-control/view/accreditation",
    meta: accreditationL3mxXxHxLkMeta || {},
    alias: accreditationL3mxXxHxLkMeta?.alias || [],
    redirect: accreditationL3mxXxHxLkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/view/accreditation.vue").then(m => m.default || m)
  },
  {
    name: customersUCRpSytTBMeta?.name ?? "app-access-control-view-customer",
    path: customersUCRpSytTBMeta?.path ?? "/app/access-control/view/customer",
    meta: customersUCRpSytTBMeta || {},
    alias: customersUCRpSytTBMeta?.alias || [],
    redirect: customersUCRpSytTBMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/view/customer.vue").then(m => m.default || m)
  },
  {
    name: orderHbUjvujhBqMeta?.name ?? "app-access-control-view-order",
    path: orderHbUjvujhBqMeta?.path ?? "/app/access-control/view/order",
    meta: orderHbUjvujhBqMeta || {},
    alias: orderHbUjvujhBqMeta?.alias || [],
    redirect: orderHbUjvujhBqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/view/order.vue").then(m => m.default || m)
  },
  {
    name: thirdparty_45ticketTDAg6bQnc6Meta?.name ?? "app-access-control-view-thirdparty-ticket",
    path: thirdparty_45ticketTDAg6bQnc6Meta?.path ?? "/app/access-control/view/thirdparty-ticket",
    meta: thirdparty_45ticketTDAg6bQnc6Meta || {},
    alias: thirdparty_45ticketTDAg6bQnc6Meta?.alias || [],
    redirect: thirdparty_45ticketTDAg6bQnc6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/view/thirdparty-ticket.vue").then(m => m.default || m)
  },
  {
    name: ticket4kRq4fCKDJMeta?.name ?? "app-access-control-view-ticket",
    path: ticket4kRq4fCKDJMeta?.path ?? "/app/access-control/view/ticket",
    meta: ticket4kRq4fCKDJMeta || {},
    alias: ticket4kRq4fCKDJMeta?.alias || [],
    redirect: ticket4kRq4fCKDJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/access-control/view/ticket.vue").then(m => m.default || m)
  },
  {
    name: indexLvt9gpJLyIMeta?.name ?? "app-communication-audiences",
    path: indexLvt9gpJLyIMeta?.path ?? "/app/communication/audiences",
    meta: indexLvt9gpJLyIMeta || {},
    alias: indexLvt9gpJLyIMeta?.alias || [],
    redirect: indexLvt9gpJLyIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/audiences/index.vue").then(m => m.default || m)
  },
  {
    name: _91emailTemplateId_93RRhid0iCktMeta?.name ?? "app-communication-build-email-emailTemplateId",
    path: _91emailTemplateId_93RRhid0iCktMeta?.path ?? "/app/communication/build/email/:emailTemplateId()",
    meta: _91emailTemplateId_93RRhid0iCktMeta || {},
    alias: _91emailTemplateId_93RRhid0iCktMeta?.alias || [],
    redirect: _91emailTemplateId_93RRhid0iCktMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/build/email/[emailTemplateId].vue").then(m => m.default || m)
  },
  {
    name: indexQ9UT3dwxzlMeta?.name ?? "app-communication-build-email",
    path: indexQ9UT3dwxzlMeta?.path ?? "/app/communication/build/email",
    meta: indexQ9UT3dwxzlMeta || {},
    alias: indexQ9UT3dwxzlMeta?.alias || [],
    redirect: indexQ9UT3dwxzlMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/build/email/index.vue").then(m => m.default || m)
  },
  {
    name: _91campaignId_93EE6u9a8w2ZMeta?.name ?? "app-communication-campaigns-campaignId",
    path: _91campaignId_93EE6u9a8w2ZMeta?.path ?? "/app/communication/campaigns/:campaignId()",
    meta: _91campaignId_93EE6u9a8w2ZMeta || {},
    alias: _91campaignId_93EE6u9a8w2ZMeta?.alias || [],
    redirect: _91campaignId_93EE6u9a8w2ZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/campaigns/[campaignId].vue").then(m => m.default || m)
  },
  {
    name: createA8rINCSNYAMeta?.name ?? "app-communication-campaigns-create",
    path: createA8rINCSNYAMeta?.path ?? "/app/communication/campaigns/create",
    meta: createA8rINCSNYAMeta || {},
    alias: createA8rINCSNYAMeta?.alias || [],
    redirect: createA8rINCSNYAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: indexTgVS8dZCr9Meta?.name ?? "app-communication-campaigns",
    path: indexTgVS8dZCr9Meta?.path ?? "/app/communication/campaigns",
    meta: indexTgVS8dZCr9Meta || {},
    alias: indexTgVS8dZCr9Meta?.alias || [],
    redirect: indexTgVS8dZCr9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexJYre8oaxG5Meta?.name ?? "app-communication",
    path: indexJYre8oaxG5Meta?.path ?? "/app/communication",
    meta: indexJYre8oaxG5Meta || {},
    alias: indexJYre8oaxG5Meta?.alias || [],
    redirect: indexJYre8oaxG5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UWDUf4nwQuMeta?.name ?? "app-communication-lists-id",
    path: _91id_93UWDUf4nwQuMeta?.path ?? "/app/communication/lists/:id()",
    meta: _91id_93UWDUf4nwQuMeta || {},
    alias: _91id_93UWDUf4nwQuMeta?.alias || [],
    redirect: _91id_93UWDUf4nwQuMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: index5BG1SQn5EJMeta?.name ?? "app-communication-lists",
    path: index5BG1SQn5EJMeta?.path ?? "/app/communication/lists",
    meta: index5BG1SQn5EJMeta || {},
    alias: index5BG1SQn5EJMeta?.alias || [],
    redirect: index5BG1SQn5EJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/lists/index.vue").then(m => m.default || m)
  },
  {
    name: indexfugg7kvhcwMeta?.name ?? "app-communication-media",
    path: indexfugg7kvhcwMeta?.path ?? "/app/communication/media",
    meta: indexfugg7kvhcwMeta || {},
    alias: indexfugg7kvhcwMeta?.alias || [],
    redirect: indexfugg7kvhcwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/media/index.vue").then(m => m.default || m)
  },
  {
    name: indexhvFWimHtrRMeta?.name ?? "app-communication-templates",
    path: indexhvFWimHtrRMeta?.path ?? "/app/communication/templates",
    meta: indexhvFWimHtrRMeta || {},
    alias: indexhvFWimHtrRMeta?.alias || [],
    redirect: indexhvFWimHtrRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/communication/templates/index.vue").then(m => m.default || m)
  },
  {
    name: indexjgRdUeuDSUMeta?.name ?? "app",
    path: indexjgRdUeuDSUMeta?.path ?? "/app",
    meta: indexjgRdUeuDSUMeta || {},
    alias: indexjgRdUeuDSUMeta?.alias || [],
    redirect: indexjgRdUeuDSUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexzXfaulSN05Meta?.name ?? "app-promote",
    path: indexzXfaulSN05Meta?.path ?? "/app/promote",
    meta: indexzXfaulSN05Meta || {},
    alias: indexzXfaulSN05Meta?.alias || [],
    redirect: indexzXfaulSN05Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/promote/index.vue").then(m => m.default || m)
  },
  {
    name: indexLARuOfhcjmMeta?.name ?? "app-reports",
    path: indexLARuOfhcjmMeta?.path ?? "/app/reports",
    meta: indexLARuOfhcjmMeta || {},
    alias: indexLARuOfhcjmMeta?.alias || [],
    redirect: indexLARuOfhcjmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dSRWUh3BSdMeta?.name ?? "app-tenant-admin-coupons-id",
    path: _91id_93dSRWUh3BSdMeta?.path ?? "/app/tenant-admin/coupons/:id()",
    meta: _91id_93dSRWUh3BSdMeta || {},
    alias: _91id_93dSRWUh3BSdMeta?.alias || [],
    redirect: _91id_93dSRWUh3BSdMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/coupons/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKS5twn2MZVMeta?.name ?? "app-tenant-admin-coupons",
    path: indexKS5twn2MZVMeta?.path ?? "/app/tenant-admin/coupons",
    meta: indexKS5twn2MZVMeta || {},
    alias: indexKS5twn2MZVMeta?.alias || [],
    redirect: indexKS5twn2MZVMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: create_45new_45eventpKWjyot2GUMeta?.name ?? "app-tenant-admin-create-new-event",
    path: create_45new_45eventpKWjyot2GUMeta?.path ?? "/app/tenant-admin/create-new-event",
    meta: create_45new_45eventpKWjyot2GUMeta || {},
    alias: create_45new_45eventpKWjyot2GUMeta?.alias || [],
    redirect: create_45new_45eventpKWjyot2GUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/create-new-event.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eexqDrNQYfMeta?.name ?? "app-tenant-admin-customers-id",
    path: _91id_93eexqDrNQYfMeta?.path ?? "/app/tenant-admin/customers/:id()",
    meta: _91id_93eexqDrNQYfMeta || {},
    alias: _91id_93eexqDrNQYfMeta?.alias || [],
    redirect: _91id_93eexqDrNQYfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfrc83ekKTxMeta?.name ?? "app-tenant-admin-customers",
    path: indexfrc83ekKTxMeta?.path ?? "/app/tenant-admin/customers",
    meta: indexfrc83ekKTxMeta || {},
    alias: indexfrc83ekKTxMeta?.alias || [],
    redirect: indexfrc83ekKTxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fOLjFiJKazMeta?.name ?? "app-tenant-admin-events-id",
    path: _91id_93fOLjFiJKazMeta?.path ?? "/app/tenant-admin/events/:id()",
    children: [
  {
    name: analyticsQvap96yhCJMeta?.name ?? "app-tenant-admin-events-id-analytics",
    path: analyticsQvap96yhCJMeta?.path ?? "analytics",
    meta: analyticsQvap96yhCJMeta || {},
    alias: analyticsQvap96yhCJMeta?.alias || [],
    redirect: analyticsQvap96yhCJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/analytics.vue").then(m => m.default || m)
  },
  {
    name: detailsbaAuzxi4KlMeta?.name ?? "app-tenant-admin-events-id-details",
    path: detailsbaAuzxi4KlMeta?.path ?? "details",
    meta: detailsbaAuzxi4KlMeta || {},
    alias: detailsbaAuzxi4KlMeta?.alias || [],
    redirect: detailsbaAuzxi4KlMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: duplicateONhrB2MZmkMeta?.name ?? "app-tenant-admin-events-id-duplicate",
    path: duplicateONhrB2MZmkMeta?.path ?? "duplicate",
    meta: duplicateONhrB2MZmkMeta || {},
    alias: duplicateONhrB2MZmkMeta?.alias || [],
    redirect: duplicateONhrB2MZmkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/duplicate.vue").then(m => m.default || m)
  },
  {
    name: guestlistJZGO3AhjywMeta?.name ?? "app-tenant-admin-events-id-guestlist",
    path: guestlistJZGO3AhjywMeta?.path ?? "guestlist",
    meta: guestlistJZGO3AhjywMeta || {},
    alias: guestlistJZGO3AhjywMeta?.alias || [],
    redirect: guestlistJZGO3AhjywMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/guestlist.vue").then(m => m.default || m)
  },
  {
    name: import_45scanned_45ticketsm6FgcMQeMgMeta?.name ?? "app-tenant-admin-events-id-import-scanned-tickets",
    path: import_45scanned_45ticketsm6FgcMQeMgMeta?.path ?? "import-scanned-tickets",
    meta: import_45scanned_45ticketsm6FgcMQeMgMeta || {},
    alias: import_45scanned_45ticketsm6FgcMQeMgMeta?.alias || [],
    redirect: import_45scanned_45ticketsm6FgcMQeMgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/import-scanned-tickets.vue").then(m => m.default || m)
  },
  {
    name: import_45ticketspVZXhb78veMeta?.name ?? "app-tenant-admin-events-id-import-tickets",
    path: import_45ticketspVZXhb78veMeta?.path ?? "import-tickets",
    meta: import_45ticketspVZXhb78veMeta || {},
    alias: import_45ticketspVZXhb78veMeta?.alias || [],
    redirect: import_45ticketspVZXhb78veMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/import-tickets.vue").then(m => m.default || m)
  },
  {
    name: imported_45ticketsBsULZIAMgbMeta?.name ?? "app-tenant-admin-events-id-imported-tickets",
    path: imported_45ticketsBsULZIAMgbMeta?.path ?? "imported-tickets",
    meta: imported_45ticketsBsULZIAMgbMeta || {},
    alias: imported_45ticketsBsULZIAMgbMeta?.alias || [],
    redirect: imported_45ticketsBsULZIAMgbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/imported-tickets.vue").then(m => m.default || m)
  },
  {
    name: integrations4fsiHKYzakMeta?.name ?? "app-tenant-admin-events-id-integrations",
    path: integrations4fsiHKYzakMeta?.path ?? "integrations",
    meta: integrations4fsiHKYzakMeta || {},
    alias: integrations4fsiHKYzakMeta?.alias || [],
    redirect: integrations4fsiHKYzakMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/integrations.vue").then(m => m.default || m)
  },
  {
    name: issued_45ticketsgx8Ezp4NbHMeta?.name ?? "app-tenant-admin-events-id-issued-tickets",
    path: issued_45ticketsgx8Ezp4NbHMeta?.path ?? "issued-tickets",
    meta: issued_45ticketsgx8Ezp4NbHMeta || {},
    alias: issued_45ticketsgx8Ezp4NbHMeta?.alias || [],
    redirect: issued_45ticketsgx8Ezp4NbHMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/issued-tickets.vue").then(m => m.default || m)
  },
  {
    name: notificationswFGPMxwtpxMeta?.name ?? "app-tenant-admin-events-id-notifications",
    path: notificationswFGPMxwtpxMeta?.path ?? "notifications",
    meta: notificationswFGPMxwtpxMeta || {},
    alias: notificationswFGPMxwtpxMeta?.alias || [],
    redirect: notificationswFGPMxwtpxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/notifications.vue").then(m => m.default || m)
  },
  {
    name: settingspMzPoUwoxTMeta?.name ?? "app-tenant-admin-events-id-settings",
    path: settingspMzPoUwoxTMeta?.path ?? "settings",
    meta: settingspMzPoUwoxTMeta || {},
    alias: settingspMzPoUwoxTMeta?.alias || [],
    redirect: settingspMzPoUwoxTMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: shop_45settingsSfjYdI2AA1Meta?.name ?? "app-tenant-admin-events-id-shop-settings",
    path: shop_45settingsSfjYdI2AA1Meta?.path ?? "shop-settings",
    meta: shop_45settingsSfjYdI2AA1Meta || {},
    alias: shop_45settingsSfjYdI2AA1Meta?.alias || [],
    redirect: shop_45settingsSfjYdI2AA1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/shop-settings.vue").then(m => m.default || m)
  },
  {
    name: ticket_45typeshC4oe1daMJMeta?.name ?? "app-tenant-admin-events-id-ticket-types",
    path: ticket_45typeshC4oe1daMJMeta?.path ?? "ticket-types",
    meta: ticket_45typeshC4oe1daMJMeta || {},
    alias: ticket_45typeshC4oe1daMJMeta?.alias || [],
    redirect: ticket_45typeshC4oe1daMJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id]/ticket-types.vue").then(m => m.default || m)
  }
],
    meta: _91id_93fOLjFiJKazMeta || {},
    alias: _91id_93fOLjFiJKazMeta?.alias || [],
    redirect: _91id_93fOLjFiJKazMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/[id].vue").then(m => m.default || m)
  },
  {
    name: indexT7T4OB7P6cMeta?.name ?? "app-tenant-admin-events",
    path: indexT7T4OB7P6cMeta?.path ?? "/app/tenant-admin/events",
    meta: indexT7T4OB7P6cMeta || {},
    alias: indexT7T4OB7P6cMeta?.alias || [],
    redirect: indexT7T4OB7P6cMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/events/index.vue").then(m => m.default || m)
  },
  {
    name: helpVm5ohsXWAhMeta?.name ?? "app-tenant-admin-help",
    path: helpVm5ohsXWAhMeta?.path ?? "/app/tenant-admin/help",
    meta: helpVm5ohsXWAhMeta || {},
    alias: helpVm5ohsXWAhMeta?.alias || [],
    redirect: helpVm5ohsXWAhMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/help.vue").then(m => m.default || m)
  },
  {
    name: index5K5HvETInoMeta?.name ?? "app-tenant-admin",
    path: index5K5HvETInoMeta?.path ?? "/app/tenant-admin",
    meta: index5K5HvETInoMeta || {},
    alias: index5K5HvETInoMeta?.alias || [],
    redirect: index5K5HvETInoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93heH3UVlizbMeta?.name ?? "app-tenant-admin-integrations-integrationId-eventId",
    path: _91eventId_93heH3UVlizbMeta?.path ?? "/app/tenant-admin/integrations/:integrationId()/:eventId()",
    meta: _91eventId_93heH3UVlizbMeta || {},
    alias: _91eventId_93heH3UVlizbMeta?.alias || [],
    redirect: _91eventId_93heH3UVlizbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/integrations/[integrationId]/[eventId].vue").then(m => m.default || m)
  },
  {
    name: indexs1etXyAchoMeta?.name ?? "app-tenant-admin-integrations-integrationId",
    path: indexs1etXyAchoMeta?.path ?? "/app/tenant-admin/integrations/:integrationId()",
    meta: indexs1etXyAchoMeta || {},
    alias: indexs1etXyAchoMeta?.alias || [],
    redirect: indexs1etXyAchoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/integrations/[integrationId]/index.vue").then(m => m.default || m)
  },
  {
    name: createluIil2hYPRMeta?.name ?? "app-tenant-admin-integrations-create",
    path: createluIil2hYPRMeta?.path ?? "/app/tenant-admin/integrations/create",
    meta: createluIil2hYPRMeta || {},
    alias: createluIil2hYPRMeta?.alias || [],
    redirect: createluIil2hYPRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/integrations/create.vue").then(m => m.default || m)
  },
  {
    name: indexd9yECM29YQMeta?.name ?? "app-tenant-admin-integrations",
    path: indexd9yECM29YQMeta?.path ?? "/app/tenant-admin/integrations",
    meta: indexd9yECM29YQMeta || {},
    alias: indexd9yECM29YQMeta?.alias || [],
    redirect: indexd9yECM29YQMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: logoutc5yPYgFrTvMeta?.name ?? "app-tenant-admin-logout",
    path: logoutc5yPYgFrTvMeta?.path ?? "/app/tenant-admin/logout",
    meta: logoutc5yPYgFrTvMeta || {},
    alias: logoutc5yPYgFrTvMeta?.alias || [],
    redirect: logoutc5yPYgFrTvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/logout.vue").then(m => m.default || m)
  },
  {
    name: messages16m79Pgg7jMeta?.name ?? "app-tenant-admin-messages",
    path: messages16m79Pgg7jMeta?.path ?? "/app/tenant-admin/messages",
    meta: messages16m79Pgg7jMeta || {},
    alias: messages16m79Pgg7jMeta?.alias || [],
    redirect: messages16m79Pgg7jMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/messages.vue").then(m => m.default || m)
  },
  {
    name: moreSDrAmObVDSMeta?.name ?? "app-tenant-admin-more",
    path: moreSDrAmObVDSMeta?.path ?? "/app/tenant-admin/more",
    children: [
  {
    name: _91id_93JhVWzouTDEMeta?.name ?? "app-tenant-admin-more-campaigns-id",
    path: _91id_93JhVWzouTDEMeta?.path ?? "campaigns/:id()",
    meta: _91id_93JhVWzouTDEMeta || {},
    alias: _91id_93JhVWzouTDEMeta?.alias || [],
    redirect: _91id_93JhVWzouTDEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/more/campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: communicationgJluZCdHwOMeta?.name ?? "app-tenant-admin-more-communication",
    path: communicationgJluZCdHwOMeta?.path ?? "communication",
    meta: communicationgJluZCdHwOMeta || {},
    alias: communicationgJluZCdHwOMeta?.alias || [],
    redirect: communicationgJluZCdHwOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/more/communication.vue").then(m => m.default || m)
  },
  {
    name: indexbmDukheGnVMeta?.name ?? "app-tenant-admin-more-emails",
    path: indexbmDukheGnVMeta?.path ?? "emails",
    meta: indexbmDukheGnVMeta || {},
    alias: indexbmDukheGnVMeta?.alias || [],
    redirect: indexbmDukheGnVMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/more/emails/index.vue").then(m => m.default || m)
  },
  {
    name: indexl2ThBsZItlMeta?.name ?? "app-tenant-admin-more-image-library",
    path: indexl2ThBsZItlMeta?.path ?? "image-library",
    meta: indexl2ThBsZItlMeta || {},
    alias: indexl2ThBsZItlMeta?.alias || [],
    redirect: indexl2ThBsZItlMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/more/image-library/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yQxGgLp44oMeta?.name ?? "app-tenant-admin-more-mailing-lists-id",
    path: _91id_93yQxGgLp44oMeta?.path ?? "mailing-lists/:id()",
    meta: _91id_93yQxGgLp44oMeta || {},
    alias: _91id_93yQxGgLp44oMeta?.alias || [],
    redirect: _91id_93yQxGgLp44oMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/more/mailing-lists/[id].vue").then(m => m.default || m)
  }
],
    meta: moreSDrAmObVDSMeta || {},
    alias: moreSDrAmObVDSMeta?.alias || [],
    redirect: moreSDrAmObVDSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/more.vue").then(m => m.default || m)
  },
  {
    name: indexuL5cRK1pRiMeta?.name ?? "app-tenant-admin-order-edit-orderid",
    path: indexuL5cRK1pRiMeta?.path ?? "/app/tenant-admin/order-edit/:orderid()",
    meta: indexuL5cRK1pRiMeta || {},
    alias: indexuL5cRK1pRiMeta?.alias || [],
    redirect: indexuL5cRK1pRiMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/order-edit/[orderid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXXkXORQR2YMeta?.name ?? "app-tenant-admin-order-edit",
    path: indexXXkXORQR2YMeta?.path ?? "/app/tenant-admin/order-edit",
    meta: indexXXkXORQR2YMeta || {},
    alias: indexXXkXORQR2YMeta?.alias || [],
    redirect: indexXXkXORQR2YMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/order-edit/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderid_93fHRVfZkNxIMeta?.name ?? "app-tenant-admin-orders-orderid",
    path: _91orderid_93fHRVfZkNxIMeta?.path ?? "/app/tenant-admin/orders/:orderid()",
    meta: _91orderid_93fHRVfZkNxIMeta || {},
    alias: _91orderid_93fHRVfZkNxIMeta?.alias || [],
    redirect: _91orderid_93fHRVfZkNxIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/orders/[orderid].vue").then(m => m.default || m)
  },
  {
    name: indexqmh6k5eTszMeta?.name ?? "app-tenant-admin-orders-create",
    path: indexqmh6k5eTszMeta?.path ?? "/app/tenant-admin/orders/create",
    meta: indexqmh6k5eTszMeta || {},
    alias: indexqmh6k5eTszMeta?.alias || [],
    redirect: indexqmh6k5eTszMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/orders/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexiCSBDgmBn6Meta?.name ?? "app-tenant-admin-orders",
    path: indexiCSBDgmBn6Meta?.path ?? "/app/tenant-admin/orders",
    meta: indexiCSBDgmBn6Meta || {},
    alias: indexiCSBDgmBn6Meta?.alias || [],
    redirect: indexiCSBDgmBn6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLbLib51IOMeta?.name ?? "app-tenant-admin-orders-search",
    path: indexXLbLib51IOMeta?.path ?? "/app/tenant-admin/orders/search",
    meta: indexXLbLib51IOMeta || {},
    alias: indexXLbLib51IOMeta?.alias || [],
    redirect: indexXLbLib51IOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/orders/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93EQN7dHTZh5Meta?.name ?? "app-tenant-admin-orders-third-party-order-orderId",
    path: _91orderId_93EQN7dHTZh5Meta?.path ?? "/app/tenant-admin/orders/third-party-order/:orderId()",
    meta: _91orderId_93EQN7dHTZh5Meta || {},
    alias: _91orderId_93EQN7dHTZh5Meta?.alias || [],
    redirect: _91orderId_93EQN7dHTZh5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/orders/third-party-order/[orderId].vue").then(m => m.default || m)
  },
  {
    name: searchgEWPcj15vlMeta?.name ?? "app-tenant-admin-search",
    path: searchgEWPcj15vlMeta?.path ?? "/app/tenant-admin/search",
    meta: searchgEWPcj15vlMeta || {},
    alias: searchgEWPcj15vlMeta?.alias || [],
    redirect: searchgEWPcj15vlMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/search.vue").then(m => m.default || m)
  },
  {
    name: settingsrL1AiMHklWMeta?.name ?? "app-tenant-admin-settings",
    path: settingsrL1AiMHklWMeta?.path ?? "/app/tenant-admin/settings",
    meta: settingsrL1AiMHklWMeta || {},
    alias: settingsrL1AiMHklWMeta?.alias || [],
    redirect: settingsrL1AiMHklWMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/settings.vue").then(m => m.default || m)
  },
  {
    name: indexR0qurtrYngMeta?.name ?? "app-tenant-admin-tickets",
    path: indexR0qurtrYngMeta?.path ?? "/app/tenant-admin/tickets",
    meta: indexR0qurtrYngMeta || {},
    alias: indexR0qurtrYngMeta?.alias || [],
    redirect: indexR0qurtrYngMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: transactionspyXt2YxytIMeta?.name ?? "app-tenant-admin-transactions",
    path: transactionspyXt2YxytIMeta?.path ?? "/app/tenant-admin/transactions",
    meta: transactionspyXt2YxytIMeta || {},
    alias: transactionspyXt2YxytIMeta?.alias || [],
    redirect: transactionspyXt2YxytIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/tenant-admin/transactions.vue").then(m => m.default || m)
  },
  {
    name: helpPYvfSan78yMeta?.name ?? "app-user-help",
    path: helpPYvfSan78yMeta?.path ?? "/app/user/help",
    meta: helpPYvfSan78yMeta || {},
    alias: helpPYvfSan78yMeta?.alias || [],
    redirect: helpPYvfSan78yMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/help.vue").then(m => m.default || m)
  },
  {
    name: indexpPgN9wEphdMeta?.name ?? "app-user",
    path: indexpPgN9wEphdMeta?.path ?? "/app/user",
    meta: indexpPgN9wEphdMeta || {},
    alias: indexpPgN9wEphdMeta?.alias || [],
    redirect: indexpPgN9wEphdMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/index.vue").then(m => m.default || m)
  },
  {
    name: ordersiNZcA9xyRsMeta?.name ?? "app-user-orders",
    path: ordersiNZcA9xyRsMeta?.path ?? "/app/user/orders",
    meta: ordersiNZcA9xyRsMeta || {},
    alias: ordersiNZcA9xyRsMeta?.alias || [],
    redirect: ordersiNZcA9xyRsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/orders.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodsZP5drF2E24Meta?.name ?? "app-user-payment-methods",
    path: payment_45methodsZP5drF2E24Meta?.path ?? "/app/user/payment-methods",
    meta: payment_45methodsZP5drF2E24Meta || {},
    alias: payment_45methodsZP5drF2E24Meta?.alias || [],
    redirect: payment_45methodsZP5drF2E24Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: plansJSy2U5PWRzMeta?.name ?? "app-user-plans",
    path: plansJSy2U5PWRzMeta?.path ?? "/app/user/plans",
    meta: plansJSy2U5PWRzMeta || {},
    alias: plansJSy2U5PWRzMeta?.alias || [],
    redirect: plansJSy2U5PWRzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/plans.vue").then(m => m.default || m)
  },
  {
    name: profilenps4DZEsATMeta?.name ?? "app-user-profile",
    path: profilenps4DZEsATMeta?.path ?? "/app/user/profile",
    children: [
  {
    name: billingBrnctE89lOMeta?.name ?? "app-user-profile-billing",
    path: billingBrnctE89lOMeta?.path ?? "billing",
    meta: billingBrnctE89lOMeta || {},
    alias: billingBrnctE89lOMeta?.alias || [],
    redirect: billingBrnctE89lOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/profile/billing.vue").then(m => m.default || m)
  },
  {
    name: generalj4B0xZ8NAWMeta?.name ?? "app-user-profile-general",
    path: generalj4B0xZ8NAWMeta?.path ?? "general",
    meta: generalj4B0xZ8NAWMeta || {},
    alias: generalj4B0xZ8NAWMeta?.alias || [],
    redirect: generalj4B0xZ8NAWMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/profile/general.vue").then(m => m.default || m)
  },
  {
    name: passwordhcADgrQb3nMeta?.name ?? "app-user-profile-password",
    path: passwordhcADgrQb3nMeta?.path ?? "password",
    meta: passwordhcADgrQb3nMeta || {},
    alias: passwordhcADgrQb3nMeta?.alias || [],
    redirect: passwordhcADgrQb3nMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/profile/password.vue").then(m => m.default || m)
  }
],
    meta: profilenps4DZEsATMeta || {},
    alias: profilenps4DZEsATMeta?.alias || [],
    redirect: profilenps4DZEsATMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/profile.vue").then(m => m.default || m)
  },
  {
    name: sharetDzigw0SHCMeta?.name ?? "app-user-share",
    path: sharetDzigw0SHCMeta?.path ?? "/app/user/share",
    meta: sharetDzigw0SHCMeta || {},
    alias: sharetDzigw0SHCMeta?.alias || [],
    redirect: sharetDzigw0SHCMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/share.vue").then(m => m.default || m)
  },
  {
    name: sign_45out0hNyVKbdWUMeta?.name ?? "app-user-sign-out",
    path: sign_45out0hNyVKbdWUMeta?.path ?? "/app/user/sign-out",
    meta: sign_45out0hNyVKbdWUMeta || {},
    alias: sign_45out0hNyVKbdWUMeta?.alias || [],
    redirect: sign_45out0hNyVKbdWUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/app/user/sign-out.vue").then(m => m.default || m)
  },
  {
    name: alt_45account_45homeElYYXN3JyBMeta?.name ?? "dev-alt-account-home",
    path: alt_45account_45homeElYYXN3JyBMeta?.path ?? "/dev/alt-account-home",
    meta: alt_45account_45homeElYYXN3JyBMeta || {},
    alias: alt_45account_45homeElYYXN3JyBMeta?.alias || [],
    redirect: alt_45account_45homeElYYXN3JyBMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/dev/alt-account-home.vue").then(m => m.default || m)
  },
  {
    name: indexTXq0M4QMwvMeta?.name ?? "dev",
    path: indexTXq0M4QMwvMeta?.path ?? "/dev",
    meta: indexTXq0M4QMwvMeta || {},
    alias: indexTXq0M4QMwvMeta?.alias || [],
    redirect: indexTXq0M4QMwvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: layout_452StXHrQTBEwMeta?.name ?? "dev-layout-2",
    path: layout_452StXHrQTBEwMeta?.path ?? "/dev/layout-2",
    meta: layout_452StXHrQTBEwMeta || {},
    alias: layout_452StXHrQTBEwMeta?.alias || [],
    redirect: layout_452StXHrQTBEwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/dev/layout-2.vue").then(m => m.default || m)
  },
  {
    name: sidebarMqjMeGJX4pMeta?.name ?? "dev-sidebar",
    path: sidebarMqjMeGJX4pMeta?.path ?? "/dev/sidebar",
    meta: sidebarMqjMeGJX4pMeta || {},
    alias: sidebarMqjMeGJX4pMeta?.alias || [],
    redirect: sidebarMqjMeGJX4pMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/dev/sidebar.vue").then(m => m.default || m)
  },
  {
    name: indexEZrSTB9SUzMeta?.name ?? "download-tickets",
    path: indexEZrSTB9SUzMeta?.path ?? "/download-tickets",
    meta: indexEZrSTB9SUzMeta || {},
    alias: indexEZrSTB9SUzMeta?.alias || [],
    redirect: indexEZrSTB9SUzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/download-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: homegkrh4VISY0Meta?.name ?? "home",
    path: homegkrh4VISY0Meta?.path ?? "/home",
    meta: homegkrh4VISY0Meta || {},
    alias: homegkrh4VISY0Meta?.alias || [],
    redirect: homegkrh4VISY0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexdATqU9DngJMeta?.name ?? "index",
    path: indexdATqU9DngJMeta?.path ?? "/",
    meta: indexdATqU9DngJMeta || {},
    alias: indexdATqU9DngJMeta?.alias || [],
    redirect: indexdATqU9DngJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKU2ePCulYbMeta?.name ?? "landing",
    path: indexKU2ePCulYbMeta?.path ?? "/landing",
    meta: indexKU2ePCulYbMeta || {},
    alias: indexKU2ePCulYbMeta?.alias || [],
    redirect: indexKU2ePCulYbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/landing/index.vue").then(m => m.default || m)
  },
  {
    name: codelqVRsXB9jEMeta?.name ?? "login-code",
    path: codelqVRsXB9jEMeta?.path ?? "/login/code",
    meta: codelqVRsXB9jEMeta || {},
    alias: codelqVRsXB9jEMeta?.alias || [],
    redirect: codelqVRsXB9jEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/login/code.vue").then(m => m.default || m)
  },
  {
    name: email_45passwordy10Ck4sNI9Meta?.name ?? "login-email-password",
    path: email_45passwordy10Ck4sNI9Meta?.path ?? "/login/email-password",
    meta: email_45passwordy10Ck4sNI9Meta || {},
    alias: email_45passwordy10Ck4sNI9Meta?.alias || [],
    redirect: email_45passwordy10Ck4sNI9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/login/email-password.vue").then(m => m.default || m)
  },
  {
    name: indexfWYCzO2tSqMeta?.name ?? "login",
    path: indexfWYCzO2tSqMeta?.path ?? "/login",
    meta: indexfWYCzO2tSqMeta || {},
    alias: indexfWYCzO2tSqMeta?.alias || [],
    redirect: indexfWYCzO2tSqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: welcomeDL4RkiDeRQMeta?.name ?? "login-welcome",
    path: welcomeDL4RkiDeRQMeta?.path ?? "/login/welcome",
    meta: welcomeDL4RkiDeRQMeta || {},
    alias: welcomeDL4RkiDeRQMeta?.alias || [],
    redirect: welcomeDL4RkiDeRQMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/login/welcome.vue").then(m => m.default || m)
  },
  {
    name: pricingnn376xy7e0Meta?.name ?? "pricing",
    path: pricingnn376xy7e0Meta?.path ?? "/pricing",
    meta: pricingnn376xy7e0Meta || {},
    alias: pricingnn376xy7e0Meta?.alias || [],
    redirect: pricingnn376xy7e0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: quickZuxhyLJOeaMeta?.name ?? "quick",
    path: quickZuxhyLJOeaMeta?.path ?? "/quick",
    meta: quickZuxhyLJOeaMeta || {},
    alias: quickZuxhyLJOeaMeta?.alias || [],
    redirect: quickZuxhyLJOeaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/quick.vue").then(m => m.default || m)
  },
  {
    name: registerQpJpUBuRhoMeta?.name ?? "register",
    path: registerQpJpUBuRhoMeta?.path ?? "/register",
    meta: registerQpJpUBuRhoMeta || {},
    alias: registerQpJpUBuRhoMeta?.alias || [],
    redirect: registerQpJpUBuRhoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexvx6NLZESZCMeta?.name ?? "scan-code",
    path: indexvx6NLZESZCMeta?.path ?? "/scan/code",
    meta: indexvx6NLZESZCMeta || {},
    alias: indexvx6NLZESZCMeta?.alias || [],
    redirect: indexvx6NLZESZCMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/scan/code/index.vue").then(m => m.default || m)
  },
  {
    name: lookupPPapZD70wEMeta?.name ?? "scan-code-lookup",
    path: lookupPPapZD70wEMeta?.path ?? "/scan/code/lookup",
    meta: lookupPPapZD70wEMeta || {},
    alias: lookupPPapZD70wEMeta?.alias || [],
    redirect: lookupPPapZD70wEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/scan/code/lookup.vue").then(m => m.default || m)
  },
  {
    name: index_45oldwhADTuJ6NSMeta?.name ?? "scan-index-old",
    path: index_45oldwhADTuJ6NSMeta?.path ?? "/scan/index-old",
    meta: index_45oldwhADTuJ6NSMeta || {},
    alias: index_45oldwhADTuJ6NSMeta?.alias || [],
    redirect: index_45oldwhADTuJ6NSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/scan/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexNp2KJn3craMeta?.name ?? "scan",
    path: indexNp2KJn3craMeta?.path ?? "/scan",
    meta: indexNp2KJn3craMeta || {},
    alias: indexNp2KJn3craMeta?.alias || [],
    redirect: indexNp2KJn3craMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/scan/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45landingWUu1L3Tsr7Meta?.name ?? "sign-up-landing",
    path: sign_45up_45landingWUu1L3Tsr7Meta?.path ?? "/sign-up-landing",
    meta: sign_45up_45landingWUu1L3Tsr7Meta || {},
    alias: sign_45up_45landingWUu1L3Tsr7Meta?.alias || [],
    redirect: sign_45up_45landingWUu1L3Tsr7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/sign-up-landing.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45oldrLgX4jR7aoMeta?.name ?? "sign-up-old",
    path: sign_45up_45oldrLgX4jR7aoMeta?.path ?? "/sign-up-old",
    meta: sign_45up_45oldrLgX4jR7aoMeta || {},
    alias: sign_45up_45oldrLgX4jR7aoMeta?.alias || [],
    redirect: sign_45up_45oldrLgX4jR7aoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/sign-up-old.vue").then(m => m.default || m)
  },
  {
    name: sign_45upUUsdSkSP41Meta?.name ?? "sign-up",
    path: sign_45upUUsdSkSP41Meta?.path ?? "/sign-up",
    meta: sign_45upUUsdSkSP41Meta || {},
    alias: sign_45upUUsdSkSP41Meta?.alias || [],
    redirect: sign_45upUUsdSkSP41Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: signedoutsJJLLejo1uMeta?.name ?? "signedout",
    path: signedoutsJJLLejo1uMeta?.path ?? "/signedout",
    meta: signedoutsJJLLejo1uMeta || {},
    alias: signedoutsJJLLejo1uMeta?.alias || [],
    redirect: signedoutsJJLLejo1uMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/signedout.vue").then(m => m.default || m)
  },
  {
    name: solutionslrpoCuuiPnMeta?.name ?? "solutions",
    path: solutionslrpoCuuiPnMeta?.path ?? "/solutions",
    meta: solutionslrpoCuuiPnMeta || {},
    alias: solutionslrpoCuuiPnMeta?.alias || [],
    redirect: solutionslrpoCuuiPnMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/solutions.vue").then(m => m.default || m)
  },
  {
    name: index4USKVWJu8sMeta?.name ?? "speech",
    path: index4USKVWJu8sMeta?.path ?? "/speech",
    meta: index4USKVWJu8sMeta || {},
    alias: index4USKVWJu8sMeta?.alias || [],
    redirect: index4USKVWJu8sMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/speech/index.vue").then(m => m.default || m)
  },
  {
    name: indexXDFAoRpbWzMeta?.name ?? "support",
    path: indexXDFAoRpbWzMeta?.path ?? "/support",
    meta: indexXDFAoRpbWzMeta || {},
    alias: indexXDFAoRpbWzMeta?.alias || [],
    redirect: indexXDFAoRpbWzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexOyC77DvOqwMeta?.name ?? "test",
    path: indexOyC77DvOqwMeta?.path ?? "/test",
    meta: indexOyC77DvOqwMeta || {},
    alias: indexOyC77DvOqwMeta?.alias || [],
    redirect: indexOyC77DvOqwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: landing1hBKg1FqIxDMeta?.name ?? "test-landing1",
    path: landing1hBKg1FqIxDMeta?.path ?? "/test/landing1",
    meta: landing1hBKg1FqIxDMeta || {},
    alias: landing1hBKg1FqIxDMeta?.alias || [],
    redirect: landing1hBKg1FqIxDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/test/landing1.vue").then(m => m.default || m)
  },
  {
    name: landing2XtogRUPpxaMeta?.name ?? "test-landing2",
    path: landing2XtogRUPpxaMeta?.path ?? "/test/landing2",
    meta: landing2XtogRUPpxaMeta || {},
    alias: landing2XtogRUPpxaMeta?.alias || [],
    redirect: landing2XtogRUPpxaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/test/landing2.vue").then(m => m.default || m)
  },
  {
    name: index1nxB9cfOXmMeta?.name ?? "tickets",
    path: index1nxB9cfOXmMeta?.path ?? "/tickets",
    meta: index1nxB9cfOXmMeta || {},
    alias: index1nxB9cfOXmMeta?.alias || [],
    redirect: index1nxB9cfOXmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: test841El2TaNqMeta?.name ?? "tickets-test",
    path: test841El2TaNqMeta?.path ?? "/tickets/test",
    meta: test841El2TaNqMeta || {},
    alias: test841El2TaNqMeta?.alias || [],
    redirect: test841El2TaNqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/tickets/test.vue").then(m => m.default || m)
  }
]